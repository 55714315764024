<template>
    <div class="container w-sm-100 w-xs-100 px-5">
        <div class="row">
            <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                <div v-if="!recipientBankDetailsSubmitted">
                    <h3 class="mt-2 mb-3 text-center">MR X has sent you xxx SGD</h3>
                    <h5 class="text-center m-3">Reference : xxxxx</h5>

                    <div class="sender-detail-form">

                        <h4 class="mt-4">Enter your SGD bank details</h4>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                                <label for="bank-account-holder-name" class="control-label">Bank Account
                                    Holder
                                    Name </label>
                                <div class="input-group">
                                    <input type="text" id="bank-account-holder-name" name="sender-address"
                                           class="form-control"
                                           v-model="recipientBankAccountHolderName">
                                </div>
                                <small v-if="validationRecipientBankAccountHolderNameError">Please enter the bank account
                                    holder name</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                                <label for="bank-account-number" class="control-label">Bank Account Number /
                                    IBAN </label>
                                <div class="input-group">
                                    <input type="text" id="bank-account-number" name="sender-city" class="form-control"
                                           v-model="recipientBankAccountNumber">
                                </div>
                                <small v-if="validationRecipientBankAccountNumberError">Please enter the bank account
                                    number</small>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                                <label for="bank-name" class="control-label">Bank Name</label>
                                <div class="input-group">
                                    <input type="text" id="bank-name" name="sender-postal-code"
                                           class="form-control" v-model="recipientBankName">
                                </div>
                                <small v-if="validationRecipientBankNameError">Please enter the bank name</small>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                                <label for="sender-province" class="control-label">BIC/SWIFT Code</label>
                                <div class="input-group">
                                    <input type="text" id="sender-province" name="sender-province" class="form-control"
                                           v-model="recipientBankSWIFTCode">
                                </div>
                                <small v-if="validationRecipientBankSWIFTCodeError">Please enter the SWIFT Code</small>
                            </div>
                        </div>
                        <br>

                        <!--POP UP MESSAGE-->
                        <!--                    <div class="confirmation-modal">-->
                        <!--                        <div class="confirmation-modal-content">-->

                        <!--                        </div>-->
                        <!--                    </div>-->


                        <div class="">
                            <button class="btn btn-block btn-primary" @click="toggleModal()">Continue</button>
                        </div>
                    </div>

                </div>
                <div v-else class="text-center">
                    <h2 class="mt-2 mb-3 text-center text-uppercase">All done</h2>

                        <check-icon size="10x" class="custom-class m-5"></check-icon>

                    <h4>
                        You will receive xxx SGD from MR x
                    </h4>
                    <h4>
                        A notification email has been sent to you and Mr X.
                    </h4>
                    <h4 class="m-2">
                        The amount will be transferred to your bank account within one business day.
                    </h4>

                    <h6 class="m-2">
                        If you have any questions, go to support@transcrypt.io
                    </h6>
                </div>


            </div>
        </div>
        <div>
            <md-dialog-confirm
                :md-active.sync="modalToggle"
                md-content="You can only insert your bank details once. Agree to proceed ?"
                md-confirm-text="OK"
                md-cancel-text="Cancel"
                @md-cancel="onCancel"
                @md-confirm="onConfirm"
            />
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { CheckIcon } from 'vue-feather-icons'

    export default {
        components: {
            CheckIcon
        },
        data() {
            return {
                recipientBankAccountHolderName: "",
                recipientBankAccountNumber: "",
                recipientBankName: "",
                recipientBankSWIFTCode: "",


                validationRecipientBankAccountHolderNameError: false,
                validationRecipientBankAccountNumberError: false,
                validationRecipientBankNameError: false,
                validationRecipientBankSWIFTCodeError: false,

                modalToggle: false,
                nextVal: false,

                recipientBankDetailsSubmitted : false,

            }

        },
        mounted() {
            //Need to receive data about who send the money, what is the amount money receive, what is the payment reference.

        },
        methods: {


            toggleModal() {


                if (!this.recipientBankAccountHolderName) {
                    this.validationRecipientBankAccountHolderNameError = true;
                } else {
                    this.validationRecipientBankAccountHolderNameError = false;
                }

                if (!this.recipientBankAccountNumber) {
                    this.validationRecipientBankAccountNumberError = true;
                } else {
                    this.validationRecipientBankAccountNumberError = false;
                }

                if (!this.recipientBankName) {
                    this.validationRecipientBankNameError = true;
                } else {
                    this.validationRecipientBankNameError = false;
                }

                if (!this.recipientBankSWIFTCode) {
                    this.validationRecipientBankSWIFTCodeError = true;
                } else {
                    this.validationRecipientBankSWIFTCodeError = false;

                }

                if (this.validationRecipientBankAccountHolderNameError || this.validationRecipientBankAccountNumberError ||
                    this.validationRecipientBankNameError || this.validationRecipientBankSWIFTCodeError) {
                    return;
                }
                else{
                    this.modalToggle = true;
                }


            },

            onCancel() {
                this.modalToggle = false;
            },

            onConfirm() {

                //send data to backend
                let data = {}
                data.recipientBankAccountHolderName = this.recipientBankAccountHolderName;
                data.recipientBankAccountNumber = this.recipientBankAccountNumber;
                data.recipientBankName = this.recipientBankName;
                data.recipientSWIFTCode = this.recipientSWIFTCode;

                this.recipientBankDetailsSubmitted = true;



            }


        },

    }
</script>
<style>

</style>